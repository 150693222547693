<template>
  <div class="bannerBox" :style="bannerStyles">
    <div class="txt">
      <h2 class="enTitle" :style="enTitleStyles">
        {{ enTitle }}
      </h2>
      <h2 class="cnTitle" :style="cnTitleStyles">
        {{ cnTitle }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerIndex',
  props: {
    backgroundImage: {
      type: String,
      default: '',
    },
    cnTitle: {
      type: String,
      default: '',
    },
    cnTitleColor: {
      type: String,
      default: '#000',
    },
    enTitle: {
      type: String,
      default: '',
    },
    enTitleColor: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {};
  },

  computed: {
    bannerStyles() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },

    enTitleStyles() {
      return {
        color: this.enTitleColor,
      };
    },

    cnTitleStyles() {
      return {
        color: this.cnTitleColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bannerBox {
  position: relative;
  height: 485px;
  font-size: 52px;
  font-weight: bold;
  text-align: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  .txt {
    position: absolute;
    top: 100px;
    right: 140px;
    .enTitle {
      font-size: 52px;
    }
    .cnTitle {
      font-size: 52px;
    }}
}
</style>
