<template>
  <!-- 分页按钮 -->
  <ElPagination
    class="pagination"
    background
    layout="prev, pager, next"
    :total="total"
    :page-size="limit"
    @current-change="handleCurrentChange"
  />
</template>

<script>
export default {
  name: 'PaginationButton',
  props: {
    pages: {
      // 默认页数
      type: Number,
      default: 1,
    },
    limit: {
      // 每页显示
      type: Number,
      default: 0,
    },
    total: {
      // 总条数
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    handleCurrentChange(e) {
      // this.$router.push({
      //   hash: 'routerhash',
      // });
      this.$emit('currentChange', e);
      document.documentElement.scrollTop = 486;
      // document.querySelector('#routerhash').scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  width: 100%;
  margin: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-pagination {
  ::v-deep.is-background {
    .el-pager {
      li:not(.disabled).active {
        background-color: #018835;
        color: #fff;
      }
    }
    .el-pager li:not(.disabled):hover {
      color: #018835;
    }
  }
 
}

</style>
