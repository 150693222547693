<template>
  <!-- 案例展示 -->
  <div class="Materials">
    <Banner v-bind="bannerProps" />
    <!-- 正文 -->
    <div class="MaterialsBox">
      <!-- 分类按钮 -->
      <div class="classBtnBox">
        <div class="left">
          主材体系
        </div>
        <div class="a">
          <template v-for="item in mainMetailClasses">
            <div
              :key="item.id"
              class="b"
              :class="{ active: item.id == mainClassId }"
              @click="mainSelect(item.id)"
            >
              <span class="right">
                {{ item.materialsClassName }}
              </span>
            </div>
          </template>
        </div>
      </div>
      <div class="classBtnBox">
        <div class="left">
          辅材体系
        </div>
        <div class="a">
          <template v-for="item in assitantMetailClasses">
            <div
              :key="item.id"
              class="b"
              :class="{ active: item.id == assistClassId }"
              @click="assistSelect(item.id)"
            >
              <span class="right">
                {{ item.materialsClassName }}
              </span>
            </div>
          </template>
        </div>
      </div>

      <!-- 案例 -->
      <div id="routerhash" class="box">
        <template v-for="item in materialsList">
          <RouterLink
            :key="item.id"
            :to="{ path: '/MaterialsContent', query: { id: item.id } }"
          >
            <div class="big">
              <div class="left">
                <img class="img" :src="item.imgUrl" alt="">
              </div>
              <div class="right">
                <span class="sup"><img :src="item.logo" alt=""></span>
                <span class="sub">
                  <h3>
                    <span>{{ item.name }}</span>
                  </h3>
                  <p>
                    <span>{{ item.synopsis }}</span>
                  </p>
                </span>
              </div>
            </div>
          </RouterLink>
        </template>
      </div>
      <!-- 分页按钮 -->
      <PaginationButton
        v-show="total > limit"
        :total="total"
        :page="pages"
        :limit="limit"
        @currentChange="currentChange"
      />
    </div>
  </div>
</template>

<script>
import { materialsClass, materials } from '../api/materials';
import Banner from '@/components/banner.vue';
import PaginationButton from '@/components/Pagination.vue';

export default {
  name: 'MaterialsIndex', 
  components: {
    Banner,
    PaginationButton,
  },
  data() {
    return {
      bannerProps: {
        backgroundImage: 'https://img.kcgeis.com/2494425522.jpg',
        cnTitle: '材料体系',
        cnTitleColor: '#018835',
        enTitle: 'MATERIAL SYSTEM',
        enTitleColor: '#fff',
      },

      did: null,
      mainClassId: null,
      assistClassId: null,
      classesBase: [
        {
          id: '',
          materialsClassName: '全部',
        },
      ],
      materialsClassList: [],

      materialsList: [],

      total: 0,
      pages: 1,
      limit: 14,
    };
  },

  computed: {
    mainMetailClasses() {
      return this.classesBase.concat(
        this.materialsClassList.filter(item => item.did === 12),
      );
    },
    assitantMetailClasses() {
      return this.classesBase.concat(
        this.materialsClassList.filter(item => item.did === 13),
      );
    },
  },

  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      async handler(newV) {
        await this.getClass();
        if (Object.keys(newV).length < 0) {
          this.getData();
        } else {
          const { index, id } = newV;
          this.did =
            Number(index) + 12 ||
            (this.assitantMetailClasses
              .map(item => item.did && item.id)
              .includes(Number(id))
              ? 13
              : 12);
          this.did === 12 ? (this.mainClassId = id) : (this.assistClassId = id);

          await this.getData();
        }
      },
    },
  },

  methods: {
    currentChange(e) {
      this.pages = e;
      this.getData();
    },

    mainSelect(id) {
      this.did = 12;
      this.assistClassId = null;
      this.mainClassId = id;
      this.getData();
    },

    assistSelect(id) {
      this.did = 13;
      this.mainClassId = null;
      this.assistClassId = id;
      this.getData();
    },

    async getClass() {
      try {
        const classData = await materialsClass();
        this.materialsClassList = classData.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    async getData() {
      // 判断主材
      if (this.did === 12) {
        // 判断没有主分类
        if (!this.mainClassId) {
          this.mainClassId = '';
        }
        this.assistClassId = null;
      } else if (this.did === 13) {
        if (!this.assistClassId) {
          this.assistClassId = '';
        }
        this.mainClassId = null;
      } else {
        this.did = 1;
        this.mainClassId = '';
      }

      try {
        const params = {
          did: this.did || '',
          materialsClassId:
            this.did === 1 ? this.mainClassId : this.assistClassId,
          limit: this.limit,
          pages: this.pages,
        };

        const infoData = await materials(params);
        const list = infoData.data.records;
        this.materialsList = list;
        this.total = infoData.data.total;
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: #fff;
  height: 100%;
  border-radius: 5px;
  background-color: #018835;
}
.Materials {
  width: 100%;
  min-height: 1000px;
  .MaterialsBox {
    width: 1200px;
    margin: 40px auto 0;
    .classBtnBox {
      // width: 100%;
      border: 1px #e5e5e5 solid;

      // background-color: pink;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left {
        height: 45px;
        width: 137px;
        background: #f9f9f9;
        font-size: 12px;
        color: #666;
        border-right: #e5e5e5 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .a {
        width: 100%;
        display: flex;
        margin-left: 10px;
        .b {
          margin: 0 10px;
        }
        .right {
          height: 100%;
          padding: 5px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s;
          &:hover {
            cursor: pointer;
            border-radius: 5px;
            background-color: #018835;
            color: #fff;
            transition: 0.5s;
          }
        }
      }
    }
  }
  .box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;

    .big {
      display: flex;
      margin-bottom: 50px;
      width: 580px;
      height: 250px;
      border: 1px solid #e5e5e5;
      &:hover .img {
        transform: scale(1.1);
        transition: all 0.5s;
      }

      .left {
        width: 45%;
        // height: 250px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .right {
        width: 55%;
        // height: 250px;
        display: flex;
        flex-direction: column;
        .sup {
          padding: 10px 20px;
          height: 70px;
          width: 100%;
          border-bottom: 1px solid #e5e5e5;
          img {
            height: 100%;
            object-fit: cover;
          }
        }
        .sub {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 25px;
          h3 {
            font-size: 24px;
            color: #131313;
            font-weight: bold;
            margin-bottom: 30px;
          }
          p {
            font-size: 14px;
            color: #666;
          }
        }
      }
    }
  }
  .pagination {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #018835;
    color: #fff;
  }
}
</style>
